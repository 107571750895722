<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <!-- <b-row> -->

          <!-- Per Page -->
          <!-- <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col> -->

          <!-- Search -->
          <!-- <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col> -->
        <!-- </b-row> -->

      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refTagsTable"
          class="position-relative"
          :items="dataRegionSpotlight"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: Region -->
          <template #cell(region_id)="data">
            <span class="text-capitalize">
              {{ data.item.region_id.toUpperCase() }}
            </span>
          </template>

          <!-- Column: CreatedAt -->
          <template #cell(created_at)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.created_at) }}
            </span>
          </template>
          <!-- Column: Is Show -->
          <template #cell(is_show)="data">
            <span class="text-capitalize">
              <b-form-checkbox
                :checked="data.item.is_show"
                class="custom-control-primary"
                name="check-button"
                switch
                :disabled="!$can('tag_update')"
                @change="switchIsShow(data.item)"
              />
            </span>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-link
              v-if="$can('update_spotlight')"
              :to="{ name: 'detail-spotlight', params: { id: data.item.value } }"
            >
              Edit
            </b-link>
          </template>

        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink, BFormCheckbox,
  BBadge, BPagination, BOverlay,
} from 'bootstrap-vue'
import TagsListAddNew from '@/components/tagManager/TagsListAddNew.vue'
import TagsListEdit from '@/components/tagManager/TagsListEdit.vue'
import moment from 'moment'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import useSpotlightList from '@/views/offeringManager/spotLightManager/spotLight'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BOverlay,
    BFormCheckbox,
    TagsListEdit,
    TagsListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    vSelect,
  },
  computed: {
    ...mapGetters('app', ['statusOptions']),
  },
  methods: {
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 600)
    },
    switchIsShow(tag) {
      const payload = tag
      // eslint-disable-next-line prefer-destructuring
      payload.is_show = !tag.is_show
      store.dispatch('product/updateTags',
        { id: tag.id, tag: payload })
        .then(() => {
          this.refreshTable()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update show success',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error update show',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    showEditBar(data) {
      this.dataTag = {
        name: data.name,
        is_show: data.is_show,
        id: data.id,
      }
      this.isEditTagsSidebarActive = true
    },
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    deleteProduct(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this tags.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('product/deleteTags', { id })
              .then(() => {
                this.refreshTable()
              })
          }
        })
    },
  },

  setup() {
    const dataRegionSpotlight = ref([
    {
        label: 'VN(Vietnam)',
        value: 'vn',
      },
      {
        label: 'MY(Malaysia)',
        value: 'my',
      },
      {
        label: 'ID(Indonesia)',
        value: 'id',
      },
      {
        label: 'SG(Singapore)',
        value: 'sg',
      },
      {
        label: 'TH(Thailand)',
        value: 'th',
      },
      {
        label: 'KR(South Korea)',
        value: 'kr',
      },
      {
        label: 'JP(Japan)',
        value: 'jp',
      },
    ])
    const isAddNewTagsSidebarActive = ref(false)
    const isEditTagsSidebarActive = ref(false)
    const {
      timeout,
      showTable,
      fetchTags,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTagsTable,
      refetchData,
    } = useSpotlightList()
    return {
      timeout,
      showTable,
      // Sidebar
      dataRegionSpotlight,
      isEditTagsSidebarActive,
      isAddNewTagsSidebarActive,
      fetchTags,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTagsTable,
      refetchData,

      // Filter
      avatarText,

      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
